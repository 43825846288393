<template>
    <span>{{ type }}</span>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'CitaStatus',
    props: {
        value: Object,
    },
    setup(props) {
        const type = computed(() => {
            if (props.value.type === 'procedimientos') {
                return props.value.procedimiento?.name || 'Procedimiento';
            }
            return 'Consulta';
        });

        return { type };
    },
};
</script>
